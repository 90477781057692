import { useState } from "react";
import { Link } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";

import { API } from "@/components/pages/settings/components/api";
import { Usage } from "@/components/pages/settings/components/usage";
import { ActionPacks } from "@/components/pages/settings/components/action-packs";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("api");

  const renderTabContent = () => {
    switch (activeTab) {
      case "api":
        return <API />;
      case "usage":
        return <Usage />;
      case "action_packs":
        return <ActionPacks />;
      default:
        return null;
    }
  };

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="items-center justify-between grid w-full gap-2">
          <h1 className="text-3xl font-semibold">Settings</h1>
        </div>
        <div className="grid w-full items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav className="grid gap-4 text-sm text-muted-foreground">
            <Link
              to="#"
              className={`font-semibold ${
                activeTab === "api" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("api")}
            >
              API
            </Link>
            <Link
              to="#"
              className={`font-semibold ${
                activeTab === "usage" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("usage")}
            >
              Usage
            </Link>
            <Link
              to="#"
              className={`font-semibold ${
                activeTab === "action_packs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("action_packs")}
            >
              Action Packs
            </Link>
          </nav>
          <div className="grid gap-6">{renderTabContent()}</div>
        </div>
      </main>
    </>
  );
};

export default Settings;
