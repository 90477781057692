import { useEffect, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AIHero } from "@/components/common/ai-hero";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { Loader2Icon } from "lucide-react";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

const Main = () => {
  const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const otpInputRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(true);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpState, setOtpState] = useState(false);
  const [authError, setAuthError] = useState("");

  const checkLoggedInStatus = async () => {
    console.log("i go 1st");
    NProgress.start();
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({
        "Content-Type": "application/json",
      });
      if (token) {
        headers.append("Authorization", `Bearer ${token}`);
      }

      const response = await fetch("/api/v1/projects", {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        console.log("Already logged in");
        NProgress.done();
        const data = await response.json();
        setAuthError("");
        localStorage.setItem("project", JSON.stringify(data.projects[0]));
        const project_id = data.projects[0].project_id;
        localStorage.setItem("project_id", project_id);
        setIsLoggedIn(true);
        navigate(`/projects/${project_id}/home`);
      } else {
        NProgress.done();
        console.log("Not logged in");
        setIsLoggedIn(false);
        setIsLoadingProject(false);
        focusOn(emailInputRef);
      }
    } catch (error: any) {
      NProgress.done();
      console.error("Error checking logged in status:", error);
      console.log(
        "Not logged in",
        otpState,
        error.message === "HTTP error! status: 403" ||
          error.message === "HTTP error! status: 404"
      );
      if (
        (!otpState && error.message === "HTTP error! status: 403") ||
        error.message === "HTTP error! status: 404"
      ) {
        NProgress.done();
        console.error("Access forbidden: Failed to load the workflow.");
        navigate("/404");
      } else if (error.message.startsWith("HTTP error! status: 5")) {
        console.error("Server Error:", error.message);
        navigate("/5xx"); // Using navigate instead of this.$router.push
      }
      NProgress.done();
      console.error("Failed to fetch default project", error);
    } finally {
    }
  };

  useEffect(() => {
    checkLoggedInStatus();
    if (isLoggedIn) {
      setIsLoadingProject(false);
    } else {
      setIsLoadingProject(true);
    }
  }, [navigate]);

  const focusOn = (inputRef: any) => {
    setTimeout(() => {
      //console.log(emailInputRef);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const emailIsValid = () => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const otpIsValid = () => {
    const pattern = /^\d{6}$/;
    return pattern.test(otp);
  };

  const getOtp = async () => {
    NProgress.start();
    if (!emailIsValid()) {
      NProgress.done();
      setAuthError("Please enter a valid email address.");
      return;
    }

    try {
      setAuthError("Mailing you an OTP...");
      const response = await fetch(
        `/api/v1/user/otp?email=${encodeURIComponent(email)}`
      );
      const data = await response.json();
      if (response.ok) {
        console.log("OTP request successful");
        setOtpState(true);
        setAuthError("");
        localStorage.setItem("session_secret", data.session_secret);
        focusOn(otpInputRef);
        NProgress.done();
      } else {
        NProgress.done();
        setOtpState(false);
        setAuthError(data.title || "OTP request failed");
        focusOn(emailInputRef);
      }
    } catch (error: any) {
      NProgress.done();
      console.error("Failed to request OTP", error);
      setOtpState(false);
      setAuthError("Failed to request OTP");
      focusOn(emailInputRef);
    }
  };

  const fetchDefaultProject = async () => {
    NProgress.start();
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({
        "Content-Type": "application/json",
      });
      if (token) {
        NProgress.done();
        headers.append("Authorization", `Bearer ${token}`);
      }

      const response = await fetch("/api/v1/projects", {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const data = await response.json();
        NProgress.done();
        console.log("Logged in successfully");
        setAuthError("");
        localStorage.setItem("project", JSON.stringify(data.projects[0]));
        const project_id = data.projects[0].project_id;
        localStorage.setItem("project_id", project_id);
        if (!isLoggedIn) {
          // Assuming fetchWorkflows is defined elsewhere in the component
          // fetchWorkflows();
        }
        setIsLoggedIn(true);
        return project_id;
      } else {
        NProgress.done();
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error: any) {
      NProgress.done();
      console.log(
        "Not logged in",
        otpState,
        error.message === "HTTP error! status: 403"
      );
      if (!otpState && error.message === "HTTP error! status: 403") {
        NProgress.done();
        console.error("Access forbidden: Failed to load the workflow.");
        logout();
      } else if (error.message.startsWith("HTTP error! status: 5")) {
        NProgress.done();
        console.error("Server Error:", error.message);
        navigate("/5xx");
      }
      NProgress.done();
      console.error("Failed to fetch default project", error);
      throw error;
    }
  };

  const verifyOtpAndLogin = async () => {
    NProgress.start();
    if (!otpIsValid()) {
      NProgress.done();
      setAuthError("Please enter a valid 6-digit OTP.");
      return;
    }
    try {
      setAuthError("Logging you in...");
      const session_secret = localStorage.getItem("session_secret");
      if (!session_secret) {
        NProgress.done();
        console.error("Session secret not found");
        setAuthError("Please start your flow again.");
        setOtpState(false);
        return;
      }
      const response = await fetch(
        `/api/v1/user/token?otp=${encodeURIComponent(
          otp
        )}&session_secret=${encodeURIComponent(session_secret)}`
      );
      const data = await response.json();
      if (response.ok) {
        NProgress.done();
        console.log("OTP verification successful, logged in");
        localStorage.setItem("token", data.token);
        localStorage.setItem("me", JSON.stringify(jwtDecode(data.me)));
        setAuthError("");
        setIsLoggedIn(true);
        await fetchDefaultProject();
        const project_id = localStorage.getItem("project_id");
        navigate(`/projects/${project_id}/home`);
      } else {
        NProgress.done();
        setIsLoggedIn(false);
        setOtpState(false);
        setAuthError(data.title || "OTP request failed. Please login again.");
        focusOn(emailInputRef);
      }
    } catch (error: any) {
      NProgress.done();
      setIsLoggedIn(false);
      setOtpState(false);
      setAuthError("Failed to verify OTP");
      focusOn(emailInputRef);
    } finally {
      NProgress.done();
      localStorage.removeItem("session_secret");
    }
  };

  const logout = async () => {
    console.log("Logout");
    setIsLoggedIn(false);
    setAuthError("Not logged in");
    setOtpState(false);

    try {
      await localStorage.removeItem("token");
      await localStorage.clear();
      NProgress.done();
    } catch (error: any) {
      NProgress.done();
      console.error("Error clearing local storage:", error);
    }

    NProgress.done();

    // Remove the Authorization header from future fetch requests
    // Note: This is handled differently than with axios, as fetch doesn't maintain default headers

    // Optionally, you might want to redirect the user to the login page
    navigate("/");
  };

  if (isLoadingProject) {
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-row ">
            <Loader2Icon className="mr-4 animate-spin" /> Loading...
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="w-full h-full lg:grid lg:grid-cols-2 flex flex-col min-h-screen">
          <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
            <div className="absolute inset-0 bg-zinc-900" />
            <AIHero />
            <div className="relative z-20 mt-auto mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 240 300"
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-20 w-20"
              >
                <path d="M120 20l100 200H20L120 20z">
                  <animate
                    attributeName="transform"
                    type="translate"
                    values="0,0; 0,-100; 0,0"
                    dur="5s"
                    repeatCount="indefinite"
                  />
                </path>
                <line x1="20" y1="280" x2="220" y2="280" />
              </svg>
            </div>
            <div className="relative z-20 mt-auto">
              <blockquote className="space-y-2">
                <p className="text-xs">
                  A.I. Hero, Inc. © All rights reserved.
                </p>
              </blockquote>
            </div>
          </div>
          <div className="flex items-center justify-center py-12">
            <div className="mx-auto grid w-[350px] gap-6">
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">Login</h1>
                {!otpState && (
                  <p className="text-balance text-muted-foreground">
                    Enter your email below to login to your account
                  </p>
                )}
                {otpState && (
                  <p className="text-balance text-muted-foreground">
                    We've sent you a one-time password to your email. Enter it
                    here.
                  </p>
                )}
              </div>
              <div className="grid gap-4">
                {authError && (
                  <div className="text-red-500 text-sm text-center">
                    {authError}
                  </div>
                )}
                {!otpState && (
                  <div className="grid gap-2">
                    {/* <Label htmlFor="email">Email</Label> */}
                    <Input
                      id="email"
                      type="email"
                      placeholder="me@example.com"
                      className="text-center"
                      required
                      ref={emailInputRef}
                      value={email}
                      onChange={(e) =>
                        setEmail((e.currentTarget as HTMLInputElement).value)
                      }
                    />
                  </div>
                )}
                {otpState && (
                  <div className="grid gap-2">
                    {/* <Label htmlFor="otp">OTP</Label> */}
                    <Input
                      id="otp"
                      type="text"
                      placeholder="Enter 6-digit OTP"
                      className="text-center"
                      required
                      ref={otpInputRef}
                      value={otp}
                      onChange={(e) =>
                        setOtp((e.currentTarget as HTMLInputElement).value)
                      }
                    />
                  </div>
                )}
                <Button
                  onClick={otpState ? verifyOtpAndLogin : getOtp}
                  type="submit"
                  className="w-full"
                >
                  {otpState ? "Verify OTP" : "Send a One-time Password (OTP)"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Main;
