import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chatbot, Conversation } from "@/types";
import {
  Loader2Icon,
  MenuIcon,
  MessageSquareIcon,
  PlusIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import MarkdownContent from "./components/markdowncontent";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

const PublicChatbot = () => {
  const [chatbot, setChatbot] = useState<Chatbot | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const { shortlink } = useParams<{ shortlink: string }>();
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [, setElapsedTime] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const fetchChatbot = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchWithProgress(
        `/api/v1/c/${shortlink}`,
        { method: "GET" },
        navigate
      );
      const data: Chatbot = await response.json();
      setChatbot(data);
    } catch (error) {
      console.error("Failed to load the chatbot:", error);
      setError("Failed to load the chatbot. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [shortlink, navigate]);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/c/${shortlink}/conversations`,
        { method: "GET" },
        navigate
      );
      const data: { conversations: Conversation[] } = await response.json();
      setConversations(data.conversations);
      if (data.conversations.length > 0) {
        // setSelectedConversation(data.conversations[0]);
      }
    } catch (error) {
      console.error("Failed to load conversations:", error);
      setError("Failed to load conversations. Please try again.");
    }
  }, [shortlink, navigate]);

  const fetchConversation = useCallback(
    async (conversationId: string, silent: boolean = false) => {
      try {
        const response = await fetchWithProgress(
          `/api/v1/c/${shortlink}/conversations/${conversationId}`,
          { method: "GET" },
          navigate,
          silent
        );
        const updatedConversation: Conversation = await response.json();
        setSelectedConversation(updatedConversation);
        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.conversation_id === updatedConversation.conversation_id
              ? updatedConversation
              : conv
          )
        );
        return updatedConversation;
      } catch (error) {
        console.error("Failed to fetch conversation:", error);
        setError("Failed to fetch conversation. Please try again.");
        return null;
      }
    },
    [shortlink, navigate]
  );

  const monitorConversation = useCallback(
    async (conversationId: string) => {
      try {
        const updatedConversation = await fetchConversation(
          conversationId,
          true
        );

        if (!updatedConversation) return;

        console.log(
          "Monitoring conversation: ",
          conversationId,
          updatedConversation.status
        );

        if (updatedConversation.current_time && updatedConversation.start_at) {
          setElapsedTime(
            Math.round(
              (new Date(updatedConversation.current_time).getTime() -
                new Date(updatedConversation.start_at).getTime()) /
                1000
            ) || 0
          );
        }

        if (["responding", "pending"].includes(updatedConversation.status)) {
          setIsSendingMessage(false);
          setTimeout(() => monitorConversation(conversationId), 100);
        }
      } catch (error: any) {
        console.error("Failed to monitor conversation:", error);
      }
    },
    [fetchConversation]
  );

  const createNewConversation = useCallback(() => {
    setSelectedConversation(null);
    setIsDisabled(false);
    setIsSheetOpen(false); // Close the sheet
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Focus on the input
      }
    }, 500);
  }, []);

  const selectConversation = useCallback(
    (conversation: Conversation) => {
      setSelectedConversation(conversation);
      monitorConversation(conversation.conversation_id);
      setIsSheetOpen(false); // Close the sheet
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Focus on the input
        }
      }, 500);
    },
    [monitorConversation]
  );

  const sendMessage = useCallback(
    async (message: string) => {
      try {
        setIsSendingMessage(true);
        setIsDisabled(true);
        let conversationId: string;
        if (!selectedConversation) {
          const response = await fetchWithProgress(
            `/api/v1/c/${shortlink}/conversations`,
            {
              method: "POST",
              body: JSON.stringify({ title: message, description: "" }),
            },
            navigate
          );
          const newConversation = await response.json();
          conversationId = newConversation.conversation_id;
          setSelectedConversation(newConversation);
        } else {
          conversationId = selectedConversation.conversation_id;
        }
        await fetchWithProgress(
          `/api/v1/c/${shortlink}/conversations/${conversationId}/messages`,
          {
            method: "POST",
            body: JSON.stringify({ role: "user", content: message }),
          },
          navigate
        );
        fetchConversations();
        monitorConversation(conversationId);
      } catch (error) {
        console.error("Failed to send message:", error);
        setError("Failed to send message. Please try again.");
      }
    },
    [shortlink, selectedConversation, navigate, monitorConversation]
  );

  const handleSendMessage = useCallback(() => {
    if (!currentMessage.trim()) return;
    sendMessage(currentMessage);
    setCurrentMessage("");
  }, [currentMessage, sendMessage]);

  const handleScrollDown = () => {
    if (selectedConversation) {
      const lastMessage =
        selectedConversation.messages[selectedConversation.messages.length - 1];
      const targetDiv = document.getElementById(lastMessage.message_id);
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const targetIndicator = document.getElementById("sending_indicator");
    if (targetIndicator) {
      targetIndicator.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSendingMessage]);

  useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  }, [selectedConversation?.status != "responding"]);

  useEffect(() => {
    fetchChatbot();
    fetchConversations();
  }, [fetchChatbot, fetchConversations]);

  useEffect(() => {
    console.log("Selected conversation updated:", selectedConversation);
    setTimeout(() => {
      handleScrollDown();
    }, 100);
    if (inputRef.current) {
      const element = inputRef.current as HTMLElement;
      element.focus();
    }
  }, [selectedConversation]);

  // useEffect(() => {
  //   if (conversations.length > 0 && !selectedConversation) {
  //     setSelectedConversation(conversations[0]);
  //   }
  // }, [conversations]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2Icon className="mr-4 animate-spin" /> Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        Error: {error}
      </div>
    );
  }

  if (!chatbot) {
    return (
      <div className="flex items-center justify-center h-screen">
        No chatbot found
      </div>
    );
  }

  const TypingIndicator = () => (
    <div className="flex items-center mb-7 space-x-2 p-2 bg-gray-200 rounded-full shadow-sm min-h-[30px] max-w-[60px] justify-center">
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"
          style={{ animationDelay: "0.2s" }}
        ></div>
        <div
          className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"
          style={{ animationDelay: "0.4s" }}
        ></div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-[100dvh]">
      <header className="flex items-center justify-between p-4 border-b">
        <h1 className="text-xl font-bold">{chatbot.name}</h1>
        <div className="flex items-center space-x-2">
          {selectedConversation && selectedConversation.status && (
            <Badge variant="outline">{selectedConversation.status}</Badge>
          )}
          <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <MenuIcon className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Conversations</SheetTitle>
              </SheetHeader>
              <div className="mt-4">
                <Button onClick={createNewConversation} className="w-full mb-4">
                  <PlusIcon className="w-4 h-4 mr-2" />
                  New Conversation
                </Button>
                <ul className="space-y-2">
                  {conversations.map((conversation) => (
                    <li key={conversation.conversation_id}>
                      <Button
                        variant={
                          selectedConversation?.conversation_id ===
                          conversation.conversation_id
                            ? "default"
                            : "ghost"
                        }
                        className="w-full justify-start"
                        onClick={() => selectConversation(conversation)}
                      >
                        <MessageSquareIcon className="w-4 h-4 mr-2" />
                        {conversation.title || "Untitled"}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </header>

      <main className="flex-grow flex flex-col h-full">
        <ScrollArea className="flex-grow px-4 py-4">
          <div className="min-h-full">
            {!selectedConversation && (
              <div className="mb-4 text-left">
                <div className="inline-block p-2 border-l-4 border-primary">
                  <MarkdownContent
                    markdown={
                      chatbot.introduction ||
                      "This is a new conversation. Please type in the box below."
                    }
                  />
                </div>
              </div>
            )}
            {selectedConversation?.messages.map((message, index) => (
              <div
                id={message.message_id}
                key={index}
                className={`mb-4 ${
                  message.role === "user" ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`inline-block p-2 ${
                    message.role === "user"
                      ? "border-r-4 border-slate-800"
                      : "border-l-4 border-primary"
                  }`}
                >
                  <MarkdownContent markdown={message.content} />
                </div>
              </div>
            ))}

            {isSendingMessage && (
              <div id="sending_indicator" className="flex">
                <div className="ml-auto">
                  <TypingIndicator />
                </div>
              </div>
            )}

            {(selectedConversation?.status === "pending" ||
              selectedConversation?.status === "responding") && (
              <div className="text-left mb-4">
                <TypingIndicator />
              </div>
            )}
          </div>
        </ScrollArea>

        <div className="p-4 border-t mt-auto">
          <div className="flex relative">
            <Input
              ref={inputRef}
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
              className="flex-grow mr-2"
              placeholder="Type your message here..."
              disabled={isDisabled}
            />
            <Button onClick={handleSendMessage} disabled={isDisabled}>
              Send
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PublicChatbot;
