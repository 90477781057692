import React, { useState, useEffect } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { ActionPack } from "@/types";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

interface ActionPacksProps {
  projectId: string;
  selectedActionPacks: string[];
  onActionPacksChange: (actionPacks: string[]) => void;
}

const ActionPacks: React.FC<ActionPacksProps> = ({
  projectId,
  selectedActionPacks,
  onActionPacksChange,
}) => {
  const [actionPacks, setActionPacks] = useState<Record<string, ActionPack>>(
    {}
  );
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    fetchActionPacks();
  }, []);

  const fetchActionPacks = async () => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${projectId}/action_packs`,
        {},
        navigate
      );
      if (!response.ok) {
        throw new Error("Failed to fetch action packs");
      }
      const data = await response.json();
      setActionPacks(data);
    } catch (error) {
      console.error("Error fetching action packs:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch action packs.",
      });
    }
  };

  const handleActionPackToggle = (actionPackId: string, checked: boolean) => {
    const updatedActionPacks = checked
      ? [...selectedActionPacks, actionPackId]
      : selectedActionPacks.filter((id) => id !== actionPackId);
    onActionPacksChange(updatedActionPacks);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        {Object.entries(actionPacks).map(([id, pack]) => (
          <div key={id} className="flex items-center space-x-2">
            <Checkbox
              id={id}
              checked={selectedActionPacks.includes(id)}
              onCheckedChange={(checked) =>
                handleActionPackToggle(id, checked as boolean)
              }
              disabled={!pack.enabled}
            />
            <Label htmlFor={id} className={pack.enabled ? "" : "text-gray-400"}>
              {pack.name || id}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionPacks;
