import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent } from "@/components/ui/card";
import { PlusCircle } from "lucide-react";
import {
  FileIcon,
  GlobeIcon,
  NotebookPenIcon,
  FolderIcon,
  PlusIcon,
  LoaderCircleIcon,
} from "lucide-react";
import { Item } from "@/types";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress"; // Import the new function
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import TableSkeleton from "@/components/common/table-skeleton";

interface ItemPickerProps {
  projectId: string;
  onSelectItems: CallableFunction;
  parentSelectedItems: Item[]; // Add selectedItems prop to receive from parent
}

const ItemPicker: React.FC<ItemPickerProps> = ({
  projectId,
  onSelectItems,
  parentSelectedItems, // Rename the prop to avoid confusion
}) => {
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddingData, setIsAddingData] = useState(false);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    fetchItems();
  }, [page]);
  useEffect(() => {
    setSelectedItems(parentSelectedItems);
  }, [parentSelectedItems]);

  const fetchItems = async () => {
    try {
      setIsFetchingItems(true);
      const response = await fetchWithProgress(
        `/api/v1/projects/${projectId}/files/items?limit=${itemsPerPage}&skip=${
          (page - 1) * itemsPerPage || 0
        }`,
        {},
        navigate
      );
      const data = await response.json();
      setItems(data.items);
      setTotalPages(data.pages);
    } catch (error: any) {
      console.error("Error fetching items:", error);
    } finally {
      setIsFetchingItems(false);
    }
  };

  const handleItemSelect = (item: Item) => {
    setSelectedItems((prevSelected: any) => {
      prevSelected = prevSelected || [];
      if (prevSelected.some((i: Item) => i.item_id === item.item_id)) {
        return prevSelected.filter((i: Item) => i.item_id !== item.item_id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleConfirm = () => {
    onSelectItems(selectedItems);
  };

  const handleUrlAdd = async (url: string) => {
    const isValidUrl = (url: string) => {
      const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return regex.test(url);
    };

    if (!isValidUrl(url)) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <GlobeIcon className="mr-3" />
            Invalid URL
          </div>
        ),
      });
      return;
    }

    setIsAddingData(true);
    await addItem({ name: url, type: "url", source: url, description: "" })
      .then(() => {
        setIsDialogOpen(false);
        setIsAddingData(false);
      })
      .then(() => {
        toast({
          description: (
            <div className="flex flex-row items-center">
              <GlobeIcon className="mr-3" />
              URL added
            </div>
          ),
        });
      });
  };

  const handleDocumentAdd = async (name: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${projectId}/files/documents`,
        {
          method: "POST",
          body: JSON.stringify({ name, description: "My document" }),
        },
        navigate
      );

      if (!response.ok) {
        throw new Error("Error creating document");
      }

      const document = await response.json();
      const documentId = document.document_id;

      await addItem({
        name,
        type: "document",
        source: `${documentId}`,
        description: name,
      })
        .then(() => {
          setIsDialogOpen(false);
          setIsAddingData(false);
        })
        .then(() => {
          toast({
            description: (
              <div className="flex flex-row items-center">
                <NotebookPenIcon className="mr-3" />
                Document added
              </div>
            ),
          });
        });

      // Navigate to the newly created document in new tab
      window.open(`/projects/${projectId}/documents/${documentId}`, "_blank");
    } catch (error: any) {
      console.error("Error adding document:", error);
      toast({
        description: (
          <div className="flex flex-row items-center">
            <GlobeIcon className="mr-3" />
            Error adding document
          </div>
        ),
      });
    }
  };

  const addItem = async (item: Item, addItem: boolean = true) => {
    item.description = item.name; // Assign name as the default description
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${projectId}/files/items`,
        {
          method: "POST",
          body: JSON.stringify(item),
        },
        navigate
      );
      if (response.ok) {
        const addedItem = await response.json();
        console.log("Item added successfully", addedItem);
        await fetchItems(); // Refresh the item list after adding
        if (addItem) {
          console.log("Selecting");
          handleItemSelect(addedItem); // Select the newly added item
        }
      } else {
        throw new Error("Error adding item");
      }
    } catch (error: any) {
      console.error("Error adding item:", error);
    }
  };

  const renderItemIcon = (type: string) => {
    switch (type) {
      case "file":
        return <FileIcon className="h-4 w-4" />;
      case "url":
        return <GlobeIcon className="h-4 w-4" />;
      case "document":
        return <NotebookPenIcon className="h-4 w-4" />;
      default:
        return <FolderIcon className="h-4 w-4" />;
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      <ScrollArea className="h-[400px] w-full rounded-md border">
        {isFetchingItems ? (
          <div className="p-2">
            <TableSkeleton />
          </div>
        ) : items.length === 0 ? (
          <Card className="text-center p-6">
            <CardContent className="min-h-32">
              <p className="text-xl mb-4">No data sources</p>
              <Button
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                <PlusCircle className="mr-2 h-4 w-4" /> Add your first data
                source.
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[50px]">Select</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Source</TableHead>
                {/* <TableHead>Description</TableHead> */}
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item: Item) => (
                <TableRow key={item.item_id}>
                  <TableCell>
                    <Checkbox
                      checked={(selectedItems || []).some(
                        (i: Item) => i.item_id === item.item_id
                      )}
                      onCheckedChange={() => handleItemSelect(item)}
                    />
                  </TableCell>
                  <TableCell>{renderItemIcon(item.type)}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  {/* <TableCell>{item.description}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </ScrollArea>

      <div className="flex justify-between items-center mt-4">
        <div className="space-x-2">
          <Button
            onClick={() => setPage((p) => Math.max(1, p - 1))}
            disabled={page === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
            disabled={page === totalPages}
          >
            Next
          </Button>
        </div>
        <span>
          Page {page} of {totalPages}
        </span>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <Button onClick={() => setIsDialogOpen(true)}>
          <PlusIcon className="mr-2" /> New
        </Button>
        <Button onClick={handleConfirm}>
          Connect {(selectedItems || []).length} Sources
        </Button>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Item</DialogTitle>
          </DialogHeader>
          <Tabs defaultValue="file">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="file">Upload File</TabsTrigger>
              <TabsTrigger value="url">Add URL</TabsTrigger>
              <TabsTrigger value="document">Add Document</TabsTrigger>
            </TabsList>
            <TabsContent value="file">
              <FilePond
                allowMultiple={true}
                maxFiles={5}
                acceptedFileTypes={["application/pdf"]}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    console.log(metadata);
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    const request = new XMLHttpRequest();
                    request.open(
                      "PUT",
                      `/api/v1/projects/${projectId}/files/uploads/${file.name}`
                    );
                    const token = localStorage.getItem("token");
                    if (token) {
                      request.setRequestHeader(
                        "Authorization",
                        `Bearer ${token}`
                      );
                    }
                    request.upload.onprogress = (e) => {
                      progress(e.lengthComputable, e.loaded, e.total);
                    };
                    request.onload = function () {
                      if (request.status >= 200 && request.status < 300) {
                        addItem({
                          name: file.name,
                          type: "file",
                          source: `uploads/${encodeURIComponent(file.name)}`,
                          description: "",
                        })
                          .then(() => {
                            setIsDialogOpen(false);
                            setIsAddingData(false);
                          })
                          .then(() => {
                            toast({
                              description: (
                                <div className="flex flex-row items-center">
                                  <FileIcon className="mr-3" />
                                  File added
                                </div>
                              ),
                            });
                          });
                        load(request.responseText);
                      } else {
                        error("oh no");
                      }
                    };
                    request.send(formData);
                    return {
                      abort: () => {
                        request.abort();
                        abort();
                      },
                    };
                  },
                }}
              />
            </TabsContent>
            <TabsContent value="url">
              <div className="relative">
                {!isAddingData ? (
                  <div>
                    <Input
                      type="url"
                      placeholder="Enter URL"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          handleUrlAdd(e.currentTarget.value);
                          e.currentTarget.value = "";
                        }
                      }}
                    />
                    <small className="absolute text-xs right-1.5 top-1.5 h-6 p-1 text-muted-foreground rounded border">
                      ENTER
                    </small>
                  </div>
                ) : (
                  <LoaderCircleIcon className="animate-spin flex align-middle justify-center" />
                )}
              </div>
            </TabsContent>
            <TabsContent value="document">
              <div className="relative">
                {!isAddingData ? (
                  <div>
                    <Input
                      type="text"
                      placeholder="Enter Document Name"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          handleDocumentAdd(
                            (e.currentTarget as HTMLInputElement).value
                          );
                          (e.currentTarget as HTMLInputElement).value = "";
                        }
                      }}
                    />
                    <small className="absolute text-xs right-1.5 top-1.5 h-6 p-1 text-muted-foreground rounded border">
                      ENTER
                    </small>
                  </div>
                ) : (
                  <LoaderCircleIcon className="animate-spin" />
                )}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ItemPicker;
