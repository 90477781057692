import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress";

interface Project {
  project_id: string;
}

interface SecretConfig {
  enabled: boolean;
  api_keys: string[];
  secret_type: string;
  actions: {
    [key: string]: {
      function: {
        description: string;
      };
    };
  };
}

interface Secrets {
  [actionPackId: string]: SecretConfig;
}

interface CurrentEditingSecret {
  actionPackId: string;
  key: string;
  value: string;
  secret_type: string;
}

export const ActionPacks: React.FC = () => {
  const [project] = useState<Project>(() => {
    const storedProject = localStorage.getItem("project");
    return storedProject ? JSON.parse(storedProject) : {};
  });
  const [secrets, setSecrets] = useState<Secrets>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentEditingSecret, setCurrentEditingSecret] =
    useState<CurrentEditingSecret | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSecrets();
  }, []);

  const fetchSecrets = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/secrets`,
        { method: "GET" },
        navigate
      );

      if (response) {
        const secretsData: Secrets = await response.json();
        setSecrets(secretsData);
      }
    } catch (error) {
      console.error("Failed to load secrets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSecret = async (actionPackId: string) => {
    const updatedSecrets = {
      ...secrets,
      [actionPackId]: {
        ...secrets[actionPackId],
        enabled: !secrets[actionPackId].enabled,
      },
    };
    setSecrets(updatedSecrets);

    try {
      await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/secrets`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action_pack_id: actionPackId,
            enabled: updatedSecrets[actionPackId].enabled,
            secret_type: secrets[actionPackId].secret_type,
          }),
        },
        navigate
      );
    } catch (error) {
      console.error("Failed to update secret enabled status:", error);
    }
  };

  const handleInputChange = (value: string) => {
    setCurrentEditingSecret((prevState) =>
      prevState ? { ...prevState, value } : null
    );
  };

  const openEditModal = async (actionPackId: string, key: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/secrets?action_pack_id=${actionPackId}&key=${key}`,
        { method: "GET" },
        navigate
      );

      if (response) {
        const secretData: { [key: string]: string } = await response.json();
        setCurrentEditingSecret({
          actionPackId,
          key,
          value: secretData[key],
          secret_type: secrets[actionPackId].secret_type,
        });
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch secret value:", error);
    }
  };

  const saveSecret = async () => {
    if (!currentEditingSecret) return;

    const { actionPackId, key, value, secret_type } = currentEditingSecret;
    try {
      await fetchWithProgress(
        `/api/v1/projects/${project.project_id}/secrets`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            action_pack_id: actionPackId,
            enabled: true,
            secret_type,
            key,
            value,
          }),
        },
        navigate
      );
      setIsModalOpen(false);
      fetchSecrets(); // Refresh secrets after saving
    } catch (error) {
      console.error("Failed to save secret:", error);
    }
  };

  const renderSecretList = (actionPackId: string, config: SecretConfig) => {
    if (config.enabled && config.api_keys) {
      return (
        <ul className="list-disc list-inside mt-2">
          {config.api_keys.map((key) => (
            <li key={key} className="flex my-2 justify-between items-center">
              {key}
              <Button
                variant="outline"
                size="sm"
                onClick={() => openEditModal(actionPackId, key)}
              >
                Edit
              </Button>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex flex-col mt-5">
          <div className="animate-pulse h-10 bg-gray-300 rounded w-full my-5"></div>
          <div className="animate-pulse my-5">
            <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 my-2 bg-gray-300 rounded w-3/4"></div>
          </div>
        </div>
      ) : Object.keys(secrets).length > 0 ? (
        <Card>
          <CardHeader>
            <CardTitle>Action Packs</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Action Pack ID</TableHead>
                  <TableHead>Enabled</TableHead>
                  <TableHead>Secret Configuration</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.entries(secrets).map(([actionPackId, config]) => (
                  <TableRow key={actionPackId}>
                    <TableCell>{actionPackId}</TableCell>
                    <TableCell>
                      <Switch
                        checked={config.enabled}
                        onCheckedChange={() => toggleSecret(actionPackId)}
                      />
                    </TableCell>
                    <TableCell>
                      <ul>
                        {Object.entries(config.actions).map(
                          ([actionName, actionData]) => (
                            <li key={actionName}>
                              {actionData.function.description}
                            </li>
                          )
                        )}
                      </ul>
                      <hr className="my-4" />
                      {renderSecretList(actionPackId, config)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          <CardFooter className="text-sm">
            <ul>
              <li>
                NOTE: Action Packs work better with 'Live' stage for your
                Chatbot or Workflow. To make them less flaky, please start your
                step/message with a '!'.
              </li>
              <li>
                <em>
                  IMPORTANT: Please roll out your secrets from time to time.
                </em>
              </li>
            </ul>
          </CardFooter>
        </Card>
      ) : (
        <div>No secrets found for this project.</div>
      )}

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Secret</DialogTitle>
          </DialogHeader>
          {currentEditingSecret && (
            <div className="py-4">
              <label htmlFor="secretValue" className="block mb-2">
                {currentEditingSecret.key}:
              </label>
              <Input
                id="secretValue"
                value={currentEditingSecret.value}
                onChange={(e) => handleInputChange(e.target.value)}
                className="w-full"
              />
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={saveSecret}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActionPacks;
