import { useState, useEffect } from "react";
import { PlusIcon, TrashIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

import ItemPicker from "./itempicker";
import { useToast } from "@/components/ui/use-toast";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Item, Inputs } from "@/types";

interface InputsComponentProps {
  inputs: Inputs;
  updateInputs: CallableFunction;
  projectId: string;
}

export const WorkflowInputs: React.FC<InputsComponentProps> = ({
  inputs,
  updateInputs,
  projectId,
}) => {
  const [showVariableForm, setShowVariableForm] = useState(false);
  const [newVariable, setNewVariable] = useState({ key: "", value: "" });
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [editingTitle, setEditingTitle] = useState("");
  const [editingValue, setEditingValue] = useState("");
  const [duplicateIdError, setDuplicateIdError] = useState(false);

  const { toast } = useToast();
  const [isEmpty, setIsEmpty] = useState(false);
  const [, setEditIsEmpty] = useState(false);

  useEffect(() => {
    const hasEmptyValues = Object.values(inputs.variables).some(
      (variable) => !variable.value
    );
    setIsEmpty(hasEmptyValues);
  }, [inputs.variables]);

  const handleCancel = () => {
    setShowVariableForm(false);
    setIsEmpty(false);
    setNewVariable({ key: "", value: "" });
  };

  const addVariable = () => {
    const key = newVariable.key
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^a-zA-Z0-9_]/g, "");
    const idExists = Object.keys(inputs.variables).includes(key);

    if (newVariable.key && newVariable.value && !idExists) {
      setIsEmpty(false);
      setDuplicateIdError(false);
      const updatedInputs = { ...inputs };
      updatedInputs.variables[key] = {
        description: "",
        title: newVariable.key,
        type: "string",
        value: newVariable.value,
      };
      updateInputs(updatedInputs);
      setNewVariable({ key: "", value: "" });
      setShowVariableForm(false);
    } else {
      setIsEmpty(true);
      setDuplicateIdError(idExists);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState<[string, string]>(["", ""]);

  const deleteInput = (toBeDeleted: [string, string]) => {
    if (!toBeDeleted.length || !toBeDeleted[0].length || !toBeDeleted[1].length)
      return;
    const type = toBeDeleted[0];
    const key = toBeDeleted[1];
    const updatedInputs: any = { ...inputs };
    if (type === "variable") {
      delete updatedInputs.variables[key];
    } else if (type === "item") {
      updatedInputs.sources = updatedInputs.sources.filter(
        (item: Item) => item.item_id !== key
      );
    }
    updateInputs(updatedInputs);
    setIsOpen(false);
    toast({
      description: (
        <div className="flex flex-row items-center">
          <TrashIcon className="mr-3 h-4 w-4" />
          Input deleted.
        </div>
      ),
    });
  };

  const handleDeleteInput = (type: string, key: string) => {
    setIsOpen(true);
    setToBeDeleted([type, key]);
  };

  const handleVariableChange = (e: any) => {
    const { name, value } = e.currentTarget;
    const validValue = value.replace(/[^a-zA-Z0-9' ]/g, "");
    setNewVariable({
      ...newVariable,
      [name]: validValue,
    });
    setIsEmpty(false);
  };

  const handleItemsSelected = (
    selectedItems: Item[],
    continueSelection: boolean = false
  ) => {
    const updatedInputs = { ...inputs };
    updatedInputs.sources = [...selectedItems];
    console.log(selectedItems);
    updateInputs(updatedInputs);
    setShowItemPicker(continueSelection);
  };

  const handleEditClick = (key: string, title: string, value: any) => {
    setEditingKey(key);
    setEditingTitle(title);
    setEditingValue(value);
    setEditMode(true);
  };

  const handleSaveEdit = (key: string) => {
    if (editingTitle === "" || editingValue === "") {
      setEditIsEmpty(true);
    } else {
      setEditIsEmpty(false);
      const updatedInputs = { ...inputs };
      updatedInputs.variables[key].title = editingTitle;
      updatedInputs.variables[key].value = editingValue;
      updateInputs(updatedInputs);
      setEditMode(false);
      setEditingKey("");
      setEditingTitle("");
      setEditingValue("");
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditIsEmpty(false);
  };

  const handleUseCachedToggle = (checked: boolean) => {
    const updatedInputs = { ...inputs, use_cached: checked };
    updateInputs(updatedInputs);
  };

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <h1 className="text-sm font-semibold">VARIABLES</h1>
        {!Object.keys(inputs.variables).length && (
          <div className="text-gray-500 text-sm">No variables added yet.</div>
        )}
        {isEmpty && (
          <div className="mt-1">
            <Label className="font-semibold text-red-600">
              All variables must have a value.
            </Label>
          </div>
        )}
        {duplicateIdError && (
          <div className="mt-1">
            <Label className="font-semibold text-red-600">
              Variable keys must be unique after replacing spaces and special
              characters.
            </Label>
          </div>
        )}
        {Object.entries(inputs.variables).map(([key, variable]) => (
          <div key={key} className="flex p-3 bg-gray-100 rounded mb-2">
            <div className="flex flex-col space-y-2 w-full">
              {editMode && editingKey === key ? (
                <>
                  <Input
                    type="text"
                    value={editingTitle}
                    onChange={(e) =>
                      setEditingTitle(
                        (e.currentTarget as HTMLInputElement).value
                      )
                    }
                    className="font-semibold text-gray-500"
                  />
                  <Input
                    type="text"
                    value={editingValue}
                    onChange={(e) =>
                      setEditingValue(
                        (e.currentTarget as HTMLInputElement).value
                      )
                    }
                  />
                  <div className="flex justify-start space-x-2">
                    <Button
                      variant={"outline"}
                      onClick={() => handleSaveEdit(key)}
                    >
                      Save
                    </Button>
                    <Button variant={"outline"} onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <span
                    className="font-semibold text-gray-500"
                    onClick={() =>
                      handleEditClick(key, variable.title, variable.value)
                    }
                  >
                    {variable.title} ({key}):
                  </span>
                  <span
                    className={`${!variable.value && "text-red-600"}`}
                    onClick={() =>
                      handleEditClick(key, variable.title, variable.value)
                    }
                  >
                    {variable.value || "No value set"}
                  </span>
                </>
              )}
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleDeleteInput("variable", key)}
            >
              <TrashIcon className="h-4 w-4" />
            </Button>
          </div>
        ))}

        {showVariableForm ? (
          <div className="space-y-3">
            <Input
              type="text"
              name="key"
              value={newVariable.key}
              onChange={handleVariableChange}
              placeholder="Variable name"
            />
            <Input
              type="text"
              name="value"
              value={newVariable.value}
              onChange={handleVariableChange}
              placeholder="Variable value"
            />
            <div className="flex justify-start space-x-2">
              <Button variant="outline" onClick={addVariable}>
                <PlusIcon className="h-4 w-4 mr-2" /> Add Variable
              </Button>
              <Button onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <Button onClick={() => setShowVariableForm(true)}>
            <PlusIcon className="h-4 w-4 mr-2" /> Add Variable
          </Button>
        )}
      </div>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to proceed?</DialogTitle>
            <DialogDescription>This action cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="destructive"
              type="button"
              onClick={() => deleteInput(toBeDeleted)}
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <hr />
      <div className="space-y-3">
        <h1 className="text-sm font-semibold">SOURCES</h1>
        {(!inputs.sources || inputs.sources.length === 0) && (
          <div className="text-gray-500 text-sm">No data items added yet.</div>
        )}
        {inputs.sources &&
          inputs.sources.map(
            (item: Item, index: number) =>
              item.item_id && (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 bg-gray-100 rounded mb-2"
                >
                  <div>
                    <span className="font-medium mr-2">
                      {truncateString(item.name, 30)}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() =>
                      handleDeleteInput("item", item.item_id || "")
                    }
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </div>
              )
          )}

        {showItemPicker ? (
          <ItemPicker
            projectId={projectId}
            onSelectItems={handleItemsSelected}
            parentSelectedItems={inputs.sources || []}
          />
        ) : (
          <Button onClick={() => setShowItemPicker(true)}>
            <PlusIcon className="h-4 w-4 mr-2" /> Add Source
          </Button>
        )}
        {inputs.sources && (
          <>
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <Switch
                  id="use-cached"
                  checked={inputs.use_cached || false}
                  onCheckedChange={handleUseCachedToggle}
                />
                <Label htmlFor="use-cached">Use Cached</Label>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkflowInputs;
