import { useState, useEffect } from "react";
import type { MouseEvent as ReactMouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import { Pin, PinOff } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, PlusCircle } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Workflow } from "@/types";

import { ScrollArea } from "@/components/ui/scroll-area";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

import {
  KindEnum,
  WorkflowTemplate,
  NoteStep,
  InstructionStep,
  TypeEnum,
  ModeEnum,
} from "@/types";

import { TrashIcon } from "lucide-react";

import { useToast } from "@/components/ui/use-toast";
import { fetchWithProgress } from "@/hooks/useFetchWithProgress"; // Import the new function
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Pencil } from "lucide-react";

const Workflows = () => {
  const { project_id } = useParams<{
    project_id: string;
  }>();
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [isFetchingWorkflows, setIsFetchingWorkflows] = useState(false);
  // @ts-ignore
  const [unauthorized, setUnauthorized] = useState(false);
  const navigate = useNavigate();

  const itemsPerPage = 6;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { toast } = useToast();

  const [toBeDeleted, setToBeDeleted] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingWorkflow, setEditingWorkflow] = useState<Workflow | null>(null);

  useEffect(() => {
    fetchWorkflows();
  }, [page]);

  const fetchWorkflows = async () => {
    try {
      setIsFetchingWorkflows(true);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows?limit=${itemsPerPage}&skip=${
          (page - 1) * itemsPerPage || 0
        }`,
        { method: "GET" },
        navigate
      );

      if (response) {
        const data = await response.json();
        const fetchedWorkflows = data.workflows || [];
        setTotalPages(data.pages);
        setWorkflows(fetchedWorkflows);
        setIsFetchingWorkflows(false);
      }
    } catch (error: any) {
      setIsFetchingWorkflows(false);
      console.error("Failed to fetch workflows", error);
    }
  };

  const handlePin = async (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    workflowId: string
  ) => {
    event.stopPropagation();
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflowId}/settings`,
        {
          method: "POST",
          body: JSON.stringify({ pinned: true }),
        },
        navigate
      );

      if (response) {
        fetchWorkflows(); // Refresh the list after pinning
      }
    } catch (error: any) {
      console.error("Failed to pin workflow", error);
    }
  };

  const handleUnpin = async (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    workflowId: string
  ) => {
    event.stopPropagation();
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${workflowId}/settings`,
        {
          method: "POST",
          body: JSON.stringify({ pinned: false }),
        },
        navigate
      );

      if (response) {
        fetchWorkflows(); // Refresh the list after unpinning
      }
    } catch (error: any) {
      console.error("Failed to unpin workflow", error);
    }
  };

  const openConfirmDeleteDialog = (toBeDeleted: string) => {
    setToBeDeleted(toBeDeleted);
    setIsOpen(true);
  };

  const handleDelete = async (toBeDeleted: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${toBeDeleted}`,
        {
          method: "DELETE",
        },
        navigate
      );

      if (response) {
        setIsOpen(false);
        fetchWorkflows(); // Refresh the list after deletion
        toast({
          description: (
            <div className="flex flex-row items-center">
              <TrashIcon className="mr-3 h-4 w-4" />
              Workflow deleted.
            </div>
          ),
        });
      }
    } catch (error: any) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <TrashIcon className="mr-3 h-4 w-4" />
            {error}
          </div>
        ),
      });
      console.error("Failed to delete workflow", error);
    }
  };

  const handleWorkflowClick = (workflowId: string) => {
    navigate(
      `/projects/${localStorage.getItem("project_id")}/workflows/${workflowId}`
    );
  };

  const addWorkflow = async (workflowTemplate: WorkflowTemplate) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows`,
        {
          method: "POST",
          body: JSON.stringify(workflowTemplate),
        },
        navigate
      );

      if (response) {
        const newWorkflow: Workflow = await response.json();

        // let hasFiles = newWorkflow.steps.some(
        //   (step) => step.type == TypeEnum.FILES
        // );

        // newWorkflow.steps = newWorkflow.steps.map((step) => {
        //   if (!hasFiles) {
        //     if (step.type === TypeEnum.INSTRUCTION) {
        //       step.mode = ModeEnum.PROCESSING;
        //     }
        //   }
        //   return step;
        // });

        await fetchWithProgress(
          `/api/v1/projects/${project_id}/autonomous/workflows/${newWorkflow.workflow_id}`,
          {
            method: "POST",
            body: JSON.stringify(newWorkflow),
          },
          navigate
        );

        navigate(
          "/projects/" + project_id + "/workflows/" + newWorkflow.workflow_id
        );
      }
    } catch (error: any) {
      console.error("Failed to create workflow", error);
    }
  };

  const addEmptyWorkflow = () => {
    addWorkflow({
      kind: KindEnum.V1,
      category: "General",
      name: "My New Workflow",
      task: "Create a simple starter workflow",
      description:
        "This is a new workflow to help you get started with AI Hero Studio",
      inputs: {
        mode: ModeEnum.EXPECTS,
        variables: {},
        use_cached: true,
        sources: [],
        description: "This is a simple starter workflow.",
      },
      steps: [
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "# Welcome to Your New AI Hero Studio Workflow!\n\nCongratulations on taking the first step towards creating powerful, AI-driven workflows. This starter template is designed to help you understand the basics and spark your creativity.",
          description: "Engaging introduction to the new workflow.",
        } as NoteStep,
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "## Getting Started\n\n1. **Explore**: Take a moment to review this workflow's structure.\n2. **Customize**: Modify the steps to suit your specific needs.\n3. **Expand**: Add new steps to create a more complex workflow.\n4. **Test**: Run your workflow to see it in action.\n\nRemember, the key to a great workflow is iterative improvement. Start simple, test often, and refine based on results.\n\nFor more detailed guidance, check out our [comprehensive documentation](https://ai-hero.notion.site/Using-AI-Hero-Studio-bdc873ee9bd443eb8f388aa7f79b14cf).\n\nHappy creating!",
          description: "Guidance on using and customizing the workflow.",
        } as NoteStep,
        {
          type: TypeEnum.INSTRUCTION,
          mode: ModeEnum.OUTPUT,
          instruction:
            "Write a short poem or haiku to express your excitement about this new workflow.",
          markdown: "[Run the workflow to see the output of this step]",
          description: "Creative task to engage users with the workflow.",
        } as InstructionStep,
        {
          type: TypeEnum.NOTE,
          mode: ModeEnum.OUTPUT,
          markdown:
            "## Congratulations!\n\nYou've successfully created a new workflow in AI Hero Studio. Feel free to explore, experiment, and innovate with your workflows to unlock their full potential.",
          description: "Closing message to celebrate the new workflow.",
        },
      ],
    } as WorkflowTemplate);
  };

  const handleUpdateWorkflow = async () => {
    if (!editingWorkflow) return;

    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/autonomous/workflows/${editingWorkflow.workflow_id}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: editingWorkflow.name,
            description: editingWorkflow.description,
          }),
        },
        navigate
      );

      if (response) {
        setIsEditDialogOpen(false);
        fetchWorkflows(); // Refresh the list after updating
        toast({
          description: "Workflow updated successfully.",
        });
      }
    } catch (error) {
      console.error("Failed to update workflow", error);
      toast({
        description: "Failed to update workflow.",
        variant: "destructive",
      });
    }
  };

  const renderPagination = () => (
    <div className="flex justify-center mt-4 items-center">
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.max(1, p - 1))}
        disabled={page === 1}
      >
        Previous
      </Button>
      <span className="mx-4">
        Page {page} of {totalPages}
      </span>
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </div>
  );

  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-semibold">Workflows</h1>
          <Button onClick={() => addEmptyWorkflow()}>
            <PlusCircle className="mr-2 h-4 w-4" /> Create New Workflow
          </Button>
        </div>

        <div className="mx-auto w-full">
          <ScrollArea className="min-h-[550px] mb-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {isFetchingWorkflows ? (
                // Skeleton loader for grid
                Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <Card key={index} className="animate-pulse">
                      <CardHeader>
                        <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                      </CardHeader>
                      <CardContent className="min-h-24">
                        <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                      </CardContent>
                      <CardFooter>
                        <div className="h-8 bg-gray-300 rounded w-1/5"></div>
                      </CardFooter>
                    </Card>
                  ))
              ) : workflows.length === 0 ? (
                <Card className="text-center p-6">
                  <CardContent className="min-h-32">
                    <p className="text-xl mb-4">No workflows found</p>
                    <Button onClick={addEmptyWorkflow}>
                      <PlusCircle className="mr-2 h-4 w-4" /> Create Your First
                      Workflow
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                workflows.map((workflow) => (
                  <Card
                    key={workflow.workflow_id}
                    className="cursor-pointer"
                    onClick={() => handleWorkflowClick(workflow.workflow_id)}
                  >
                    <CardHeader>
                      <CardTitle>{workflow.name}</CardTitle>
                      <CardDescription>
                        {/* <Badge variant="outline">{workflow.status}</Badge> */}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="min-h-32">
                      <p className="text-sm text-gray-600 mb-2">
                        {new Date(
                          workflow.updated_at || workflow.created_at
                        ).toLocaleString()}
                      </p>
                      <p className="line-clamp-3">{workflow.description}</p>
                    </CardContent>
                    <CardFooter className="flex justify-between">
                      <div className="flex">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            workflow.pinned
                              ? handleUnpin(e, workflow.workflow_id)
                              : handlePin(e, workflow.workflow_id);
                          }}
                        >
                          {workflow.pinned ? (
                            <Pin className="h-4 w-4 text-primary" /> // Solid primary icon
                          ) : (
                            <PinOff className="h-4 w-4 text-black" /> // Outline black icon
                          )}
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingWorkflow(workflow);
                            setIsEditDialogOpen(true);
                          }}
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                      </div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MoreHorizontal className="h-4 w-4" />
                            <span className="sr-only">Open menu</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              openConfirmDeleteDialog(workflow.workflow_id);
                            }}
                          >
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </CardFooter>
                  </Card>
                ))
              )}
            </div>
          </ScrollArea>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Are you sure you want to proceed?</DialogTitle>
                <DialogDescription>
                  This action cannot be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant="destructive"
                  type="button"
                  onClick={() => handleDelete(toBeDeleted)}
                >
                  Confirm
                </Button>
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit Workflow</DialogTitle>
                <DialogDescription>
                  Update the details of your workflow.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="edit-name" className="text-right">
                    Name
                  </Label>
                  <Input
                    id="edit-name"
                    value={editingWorkflow?.name || ""}
                    onChange={(e) =>
                      setEditingWorkflow((prev) =>
                        prev ? { ...prev, name: e.target.value } : null
                      )
                    }
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="edit-description" className="text-right">
                    Description
                  </Label>
                  <Textarea
                    id="edit-description"
                    value={editingWorkflow?.description || ""}
                    onChange={(e) =>
                      setEditingWorkflow((prev) =>
                        prev ? { ...prev, description: e.target.value } : null
                      )
                    }
                    className="col-span-3"
                  />
                </div>
              </div>
              <DialogFooter>
                <Button type="submit" onClick={handleUpdateWorkflow}>
                  Update Workflow
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          {totalPages > 1 && renderPagination()}
        </div>
      </main>
    </>
  );
};

export default Workflows;
