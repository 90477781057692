import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { AIHero } from "@/components/common/ai-hero";
import { MainNav } from "@/components/common/main-nav";
import { ResponsiveNav } from "@/components/common/responsive-nav";
import { UserNav } from "@/components/common/user-nav";
import TableSkeleton from "@/components/common/table-skeleton";
import { Card, CardContent } from "@/components/ui/card";
import { PlusCircle } from "lucide-react";

import {
  LoaderCircleIcon,
  FolderIcon,
  NotebookPenIcon,
  GlobeIcon,
  PlusIcon,
  FileIcon,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import { DialogDescription } from "@radix-ui/react-dialog";

import { fetchWithProgress } from "@/hooks/useFetchWithProgress";
import { Item } from "@/types";

registerPlugin(FilePondPluginFileValidateType);

const FolderStructure = () => {
  const [folders] = useState([{ name: "shared", children: [] }]);
  const [currentFolder, setCurrentFolder] = useState("shared");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const { project_id } = useParams<{
    project_id: string;
  }>();

  const [isAddingData, setIsAddingData] = useState(false);
  const [isFetchingItems, setIsFetchingItems] = useState(false);

  const navigate = useNavigate();
  const { toast } = useToast();

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  useEffect(() => {
    fetchItems();
  }, [currentFolder, page]);

  const fetchItems = async () => {
    try {
      setIsFetchingItems(true);
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/files/items?limit=${itemsPerPage}&skip=${
          (page - 1) * itemsPerPage || 0
        }`,
        {},
        navigate
      );
      const data = await response.json();
      setItems(data.items);
      setTotalPages(data.pages);
    } catch (error: any) {
      console.error("Error fetching items:", error);
    } finally {
      setIsFetchingItems(false);
    }
  };

  const addItem = async (item: Item) => {
    item.description = item.name;
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/files/items`,
        {
          method: "POST",
          body: JSON.stringify(item),
        },
        navigate
      );
      if (response.ok) {
        fetchItems();
      } else {
        throw new Error("Error adding item");
      }
    } catch (error: any) {
      console.error("Error adding item:", error);
    }
  };

  const handleUrlAdd = async (url: string) => {
    const isValidUrl = (url: string) => {
      const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return regex.test(url);
    };

    if (!isValidUrl(url)) {
      toast({
        description: (
          <div className="flex flex-row items-center">
            <GlobeIcon className="mr-3" />
            Invalid URL
          </div>
        ),
      });
      return;
    }

    setIsAddingData(true);
    await addItem({ name: url, type: "url", source: url, description: "" })
      .then(() => {
        setIsDialogOpen(false);
        setIsAddingData(false);
      })
      .then(() => {
        toast({
          description: (
            <div className="flex flex-row items-center">
              <GlobeIcon className="mr-3" />
              URL added
            </div>
          ),
        });
      });
  };

  const handleDocumentAdd = async (name: string) => {
    try {
      const response = await fetchWithProgress(
        `/api/v1/projects/${project_id}/files/documents`,
        {
          method: "POST",
          body: JSON.stringify({ name, description: "My document" }),
        },
        navigate
      );

      if (!response.ok) {
        throw new Error("Error creating document");
      }

      const document = await response.json();
      console.log("Document created:", document);
      const document_id = document.document_id;
      addItem({
        name,
        type: "document",
        source: `${document_id}`,
        description: name,
      })
        .then(() => {
          setIsDialogOpen(false);
          setIsAddingData(false);
        })
        .then(() => {
          toast({
            description: (
              <div className="flex flex-row items-center">
                <NotebookPenIcon className="mr-3" />
                Document Added
              </div>
            ),
          });
        });

      setIsDialogOpen(false);
      toast({
        description: (
          <div className="flex flex-row items-center">
            <NotebookPenIcon className="mr-3" />
            Document added
          </div>
        ),
      });

      // Navigate to the newly created document
      navigate(`/projects/${project_id}/documents/${document_id}`);
    } catch (error: any) {
      console.error("Error adding document:", error);
      toast({
        description: (
          <div className="flex flex-row items-center">
            <GlobeIcon className="mr-3" />
            Error adding document
          </div>
        ),
      });
    }
  };

  const renderFolderList = () => (
    <div className="mb-4">
      {folders.map((folder) => (
        <Button
          key={folder.name}
          variant={currentFolder === folder.name ? "secondary" : "ghost"}
          className="mr-2"
          onClick={() => setCurrentFolder(folder.name)}
        >
          <FolderIcon className="mr-2" />
          {folder.name}
        </Button>
      ))}
    </div>
  );

  const renderFileList = () => (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Type</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>Updated</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="">
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              {item.type === "file" ? (
                <Link
                  to={`/projects/${project_id}/uploads/${decodeURI(
                    item.source
                  )}`}
                  className="text-sm font-medium transition-colors hover:text-primary"
                >
                  <FileIcon className="inline mr-2" />
                </Link>
              ) : item.type === "url" ? (
                <Link
                  to={`${item.source}`}
                  target="_blank"
                  className="text-sm font-medium transition-colors hover:text-primary"
                >
                  <GlobeIcon className="inline mr-2" />
                </Link>
              ) : item.type === "document" ? (
                <Link
                  to={`/projects/${project_id}/documents/${item.source}`}
                  className="text-sm font-medium transition-colors hover:text-primary"
                >
                  <NotebookPenIcon className="inline mr-2" />
                </Link>
              ) : (
                <FolderIcon className="inline mr-2" />
              )}
            </TableCell>
            <TableCell className="font-medium">
              {truncateString(item.name, 30)}
            </TableCell>
            <TableCell className="font-medium">
              {truncateString(item.description, 30)}
            </TableCell>
            <TableCell className="font-medium">{item.updated_at}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderPagination = () => (
    <div className="flex justify-center mt-4 items-center">
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.max(1, p - 1))}
        disabled={page === 1}
      >
        Previous
      </Button>
      <span className="mx-4">
        Page {page} of {totalPages}
      </span>
      <Button
        className="w-24"
        onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </div>
  );

  return (
    <>
      {renderFolderList()}

      <div className="flex justify-between items-center">
        <span className="text-lg font-semibold">/{currentFolder}</span>
        <Button onClick={() => setIsDialogOpen(true)}>
          <PlusIcon className="mr-2" /> New
        </Button>
      </div>

      <ScrollArea className="min-h-[450px]">
        {isFetchingItems ? (
          <TableSkeleton />
        ) : items.length === 0 ? (
          <Card className="text-center p-6">
            <CardContent className="min-h-32">
              <p className="text-xl mb-4">No data sources</p>
              <Button
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                <PlusCircle className="mr-2 h-4 w-4" /> Add.
              </Button>
            </CardContent>
          </Card>
        ) : (
          renderFileList()
        )}
      </ScrollArea>

      {totalPages > 1 && renderPagination()}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Item</DialogTitle>
            <DialogDescription>
              Add a new file, URL, or document to the project
            </DialogDescription>
          </DialogHeader>
          <Tabs defaultValue="file">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="file">Upload File</TabsTrigger>
              <TabsTrigger value="url">Add URL</TabsTrigger>
              <TabsTrigger value="document">Add Document</TabsTrigger>
            </TabsList>
            <TabsContent value="file">
              <FilePond
                allowMultiple={true}
                maxFiles={5}
                acceptedFileTypes={["application/pdf"]}
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    console.log(metadata);
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    const request = new XMLHttpRequest();
                    request.open(
                      "PUT",
                      `/api/v1/projects/${project_id}/files/uploads/${file.name}`
                    );
                    const token = localStorage.getItem("token");
                    if (token) {
                      request.setRequestHeader(
                        "Authorization",
                        `Bearer ${token}`
                      );
                    }
                    request.upload.onprogress = (e) => {
                      progress(e.lengthComputable, e.loaded, e.total);
                    };
                    request.onload = function () {
                      if (request.status >= 200 && request.status < 300) {
                        addItem({
                          name: file.name,
                          type: "file",
                          source: `uploads/${encodeURIComponent(file.name)}`,
                          description: "",
                        })
                          .then(() => {
                            setIsDialogOpen(false);
                            setIsAddingData(false);
                          })
                          .then(() => {
                            toast({
                              description: (
                                <div className="flex flex-row items-center">
                                  <FileIcon className="mr-3" />
                                  File added
                                </div>
                              ),
                            });
                          });
                        load(request.responseText);
                      } else {
                        error("oh no");
                      }
                    };
                    request.send(formData);
                    return {
                      abort: () => {
                        request.abort();
                        abort();
                      },
                    };
                  },
                }}
              />
            </TabsContent>
            <TabsContent value="url">
              <div className="relative">
                {!isAddingData ? (
                  <div>
                    <Input
                      type="url"
                      placeholder="Enter URL"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          handleUrlAdd(e.currentTarget.value);
                          e.currentTarget.value = "";
                        }
                      }}
                    />
                    <small className="absolute text-xs right-1.5 top-1.5 h-6 p-1 text-muted-foreground rounded border">
                      ENTER
                    </small>
                  </div>
                ) : (
                  <LoaderCircleIcon className="animate-spin flex align-middle justify-center" />
                )}
              </div>
            </TabsContent>
            <TabsContent value="document">
              <div className="relative">
                {!isAddingData ? (
                  <div>
                    <Input
                      type="text"
                      placeholder="Enter Document Name"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          handleDocumentAdd(
                            (e.currentTarget as HTMLInputElement).value
                          );
                          (e.currentTarget as HTMLInputElement).value = "";
                        }
                      }}
                    />
                    <small className="absolute text-xs right-1.5 top-1.5 h-6 p-1 text-muted-foreground rounded border">
                      ENTER
                    </small>
                  </div>
                ) : (
                  <div>
                    <LoaderCircleIcon className="animate-spin" />
                  </div>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Data = () => {
  return (
    <>
      <header className="flex h-16 items-center justify-between border-b bg-background px-6">
        <nav className="hidden md:flex space-x-6">
          <AIHero />
          <MainNav />
        </nav>
        <ResponsiveNav />
        <div>
          <UserNav />
        </div>
      </header>

      <main className="container space-y-7 p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-semibold">Data</h1>
        </div>
        <FolderStructure />
      </main>
    </>
  );
};

export default Data;
