import "@/App.css";

import { Route, Routes } from "react-router-dom";

import Home from "@/components/pages/home";
import Settings from "@/components/pages/settings";
import Workflows from "@/components/pages/workflows";
import Chatbots from "@/components/pages/chatbots";
import Data from "@/components/pages/data";
import Main from "@/components/pages/main";
import SingleWorkflow from "./components/pages/single_workflow";
import SingleChatbot from "./components/pages/single_chatbot";
import { Toaster } from "@/components/ui/toaster";
import { NotFound } from "@/components/pages/404";
import { PaymentRequired } from "@/components/pages/402";
import { ServerError } from "@/components/pages/5xx";

import Personas from "@/components/pages/personas";
import SinglePersona from "@/components/pages/single_persona";
import SingleDocument from "@/components/pages/single_document";
import { SingleUpload } from "@/components/pages/single_upload";
import PublicChatbot from "@/components/pages/single_public_chatbot";
import { ThemeProvider } from "@/components/common/theme-provider";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <>
      <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/402" element={<PaymentRequired />} />
          <Route path="/5xx" element={<ServerError />} />
          <Route path="/projects/:project_id/home" element={<Home />} />
          <Route path="/projects/:project_id/settings" element={<Settings />} />
          <Route path="/projects/:project_id/data" element={<Data />} />
          <Route
            path="/projects/:project_id/workflows"
            element={<Workflows />}
          />
          <Route path="/projects/:project_id/chatbots" element={<Chatbots />} />
          <Route
            path="/projects/:project_id/workflows/:workflow_id"
            element={<SingleWorkflow />}
          />
          <Route
            path="/projects/:project_id/chatbots/:chatbot_id"
            element={<SingleChatbot />}
          />
          <Route path="/personas" element={<Personas />} />
          <Route path="/personas/:persona_id" element={<SinglePersona />} />
          <Route
            path="/projects/:project_id/documents/:document_id"
            element={<SingleDocument />}
          />
          <Route
            path="/projects/:project_id/uploads/:filename"
            element={<SingleUpload />}
          />
          <Route path="/c/:shortlink" element={<PublicChatbot />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
      </ThemeProvider>
    </>
  );
}

export default App;
